button {
	background-color: transparent;
	border: none;
	color: var(--color-black, #000);
	cursor: pointer;
	margin: 0;
	padding: 0;
}

button:focus {
	outline: none;
}

button:focus-visible {
	outline: auto;
}

/* For the safari polyfill */
button.focus-visible {
	outline: auto;
}
