/* global declarations */
html {
	font-size: 62.5%; /* sets the default size to 10px */
	text-size-adjust: 100%;
	touch-action: manipulation;
}

/* generic, animation and helper styles */
html,
body {
	height: 100%;
}

body {
	background: #fff;
	min-width: 0;
}

::selection {
	background: var(--color-progressive-red);
	color: #fff;
}

img::selection {
	background: transparent;
	color: transparent;
}

/* Links */
a {
	color: #000;
	text-decoration: none;
}

/* Table  */
/* for a special table see modules.css */
table {
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}

th {
	background: #fff;
	border-right: 2px solid #fff;
	padding: 0 15px 10px;
	text-align: right;
}

tr {
	background: #e7e9ea;
	display: table-row;
}

td {
	border-bottom: 2px solid #fff;
	border-right: 2px solid #fff;
	padding: 10px;
}

tr:last-child td {
	border-bottom: none;
}

tr:active,
tr:focus,
tr:hover {
	background: #e9ebed;
}

strong,
b {
	font-weight: 700;
}

/* sup and sub */
sup,
sub {
	font-size: 10px;
	position: relative;
}

.legacy sup {
	top: -5px;
}

.legacy sub {
	bottom: -3px;
}

/* prevent event stealing from inline svgs (in links) */
svg {
	pointer-events: none;
}
